import React from 'react';
import Layout from "../../components/layout";

const LandsDaleMovedToWangara = () => {
    return (
        <Layout>
            <h1>The Landsdale Dojo in Perth WA has now moved to Wangara</h1>
            <p>Due to the closure of the Landsdale Dojo the north perth TOGKA branch has now moved to Wangara.</p>
            <p>For further details go to the <a href="/locations/Wangara/">Wangara</a> page.</p>
        </Layout>
    )
}

export default LandsDaleMovedToWangara;
